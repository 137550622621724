import React from 'react';

const Info = () => {
  return (
    <div className="about__info">
      <div className="about__box">
        <i className="bx bx-award about__icon"></i>
        <h3 className="about__title">Experience</h3>
        <span className="about__subtitle">6+ Years Working</span>
      </div>

      <div className="about__box">
        <i className="bx bx-briefcase-alt about__icon"></i>
        <h3 className="about__title">Completed</h3>
        <span className="about__subtitle">70+ Projects</span>
      </div>

      <div className="about__box">
        <i className="bx bx-user-plus about__icon"></i>
        <h3 className="about__title">Clients</h3>
        <span className="about__subtitle">30+ Worldwide</span>
      </div>

      <div className="about__box">
        <i className="bx bx-rocket about__icon"></i>
        <h3 className="about__title">Deployed</h3>
        <span className="about__subtitle">20+ Apps</span>
      </div>
    </div>
  );
};

export default Info;
